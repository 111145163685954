var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "height100" },
    [
      _c(
        "header-title",
        { attrs: { title: _vm.title } },
        [
          _c(
            "rs-button",
            {
              staticClass: "button-click",
              attrs: { slot: "center", size: "small", type: "primary" },
              on: { click: _vm.editRole },
              slot: "center",
            },
            [_vm._v("编辑")]
          ),
        ],
        1
      ),
      _c(
        "rs-row",
        {},
        [
          _c(
            "rs-form",
            {
              ref: "editForm",
              staticClass: "form-readonly demo-ruleForm form-70",
              staticStyle: { "padding-right": "20px" },
              attrs: {
                size: "small",
                model: _vm.editForm,
                "label-width": "156px",
                "label-position": "right",
                "status-icon": "",
                rules: _vm.accountRules,
              },
            },
            [
              _c(
                "rs-col",
                { attrs: { span: 12 } },
                [
                  _c("rs-form-item", { attrs: { label: "角色名称:" } }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.editForm.roleName) +
                        "\n                "
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "rs-col",
                { attrs: { span: 12 } },
                [
                  _c("rs-form-item", { attrs: { label: "角色状态:" } }, [
                    _c("span", [
                      _vm._v(_vm._s(_vm._f("status")(_vm.editForm.status))),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "rs-col",
                { attrs: { span: 12 } },
                [
                  _c("rs-form-item", { attrs: { label: "角色描述:" } }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.editForm.remark) +
                        "\n                "
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "rs-col",
                { attrs: { span: 12 } },
                [
                  _c("rs-form-item", { attrs: { label: "创建人:" } }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.editForm.createName) +
                        "\n                "
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "rs-col",
                { attrs: { span: 12 } },
                [
                  _c("rs-form-item", { attrs: { label: "创建时间:" } }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.editForm.createTime) +
                        "\n                "
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "rs-col",
                { attrs: { span: 12 } },
                [
                  _c("rs-form-item", { attrs: { label: "更新人:" } }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.editForm.modifyName) +
                        "\n                "
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "rs-col",
                { attrs: { span: 12 } },
                [
                  _c("rs-form-item", { attrs: { label: "更新时间:" } }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.editForm.modifyTime) +
                        "\n                "
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "rs-col",
            {
              staticStyle: { padding: "14px 0 14px 14px" },
              attrs: { span: 24 },
            },
            [
              _c(
                "rs-tabs",
                {
                  attrs: { type: "card" },
                  model: {
                    value: _vm.authTab,
                    callback: function ($$v) {
                      _vm.authTab = $$v
                    },
                    expression: "authTab",
                  },
                },
                [
                  _c(
                    "rs-tab-pane",
                    {
                      attrs: {
                        label: "该角色拥有的权限",
                        name: "shujuquanxian",
                      },
                    },
                    [_c("auth", { staticStyle: { "max-width": "70%" } })],
                    1
                  ),
                  _c(
                    "rs-tab-pane",
                    {
                      attrs: {
                        label: "拥有该角色的用户",
                        name: "juesequanxian",
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "table-box" },
                        [
                          _c(
                            "rs-table",
                            {
                              staticClass: "my-table drawer-table",
                              attrs: {
                                data: _vm.tableData,
                                "highlight-current-row": "",
                                border: "",
                                "max-height": "657px",
                                "tooltip-effect": "light",
                              },
                            },
                            [
                              _c("rs-table-column", {
                                attrs: {
                                  prop: "userName",
                                  label: "员工姓名",
                                  align: "left",
                                },
                              }),
                              _c("rs-table-column", {
                                attrs: {
                                  prop: "userCode",
                                  label: "账户ID",
                                  align: "left",
                                },
                              }),
                              _c("rs-table-column", {
                                attrs: {
                                  label: "岗位",
                                  prop: "stationName",
                                  "show-overflow-tooltip": "",
                                  align: "left",
                                },
                              }),
                              _c("rs-table-column", {
                                attrs: {
                                  label: "组织",
                                  prop: "orgFullName",
                                  "show-overflow-tooltip": "",
                                  align: "left",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }