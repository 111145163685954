<template>
    <div class="height100">
        <header-title :title="title">
            <rs-button size="small" slot="center" class="button-click" type="primary" @click="editRole">编辑</rs-button>
        </header-title>
        <rs-row class="">
            <rs-form size="small" class="form-readonly demo-ruleForm form-70" :model="editForm" label-width="156px" style="padding-right: 20px;" label-position="right" status-icon :rules="accountRules" ref="editForm">
                <rs-col :span="12">
                    <rs-form-item label="角色名称:">
                        {{editForm.roleName}}
                    </rs-form-item>
                </rs-col>
                <rs-col :span="12">
                    <rs-form-item label="角色状态:">
                        <span>{{editForm.status | status}}</span>
                    </rs-form-item>
                </rs-col>
                <rs-col :span="12">
                    <rs-form-item label="角色描述:">
                        {{editForm.remark}}
                    </rs-form-item>
                </rs-col>
                <rs-col :span="12">
                    <rs-form-item label="创建人:">
                        {{editForm.createName}}
                    </rs-form-item>
                </rs-col>
                <rs-col :span="12">
                    <rs-form-item label="创建时间:">
                        {{editForm.createTime}}
                    </rs-form-item>
                </rs-col>
                <rs-col :span="12">
                    <rs-form-item label="更新人:">
                        {{editForm.modifyName}}
                    </rs-form-item>
                </rs-col>
                <rs-col :span="12">
                    <rs-form-item label="更新时间:">
                        {{editForm.modifyTime}}
                    </rs-form-item>
                </rs-col>
            </rs-form>
            <rs-col :span="24" style="padding: 14px 0 14px 14px">
                <rs-tabs v-model="authTab" type="card">
                    <rs-tab-pane label="该角色拥有的权限" name="shujuquanxian">
                        <auth style="max-width: 70%;"></auth>
                    </rs-tab-pane>
                    <rs-tab-pane label="拥有该角色的用户" name="juesequanxian">
                        <div class="table-box">
                            <rs-table
                                class="my-table drawer-table"
                                :data="tableData"
                                highlight-current-row
                                border
                                max-height="657px"
                                tooltip-effect="light"
                                >
                                <rs-table-column
                                    prop="userName"
                                    label="员工姓名"
                                    align="left">
                                    <!-- <template slot-scope="scope">
                                        <rs-button type="text" size="small">{{scope.row.userName}}</rs-button>
                                    </template> -->
                                </rs-table-column>
                                <rs-table-column
                                    prop="userCode"
                                    label="账户ID"
                                    align="left">
                                </rs-table-column>
                                <rs-table-column
                                    label="岗位"
                                    prop="stationName"
                                    show-overflow-tooltip
                                    align="left">
                                </rs-table-column>
                                <rs-table-column
                                    label="组织"
                                    prop="orgFullName"
                                    show-overflow-tooltip
                                    align="left">
                                </rs-table-column>
                            </rs-table>
                        </div>
                    </rs-tab-pane>
                </rs-tabs>
            </rs-col>
        </rs-row>
    </div>
</template>

<script>
    import Http from '@/api/api'
    import utils from '@/utils/utils'
    import auth from '../authDetail'
    import headerTitle from '@/components/header'
    export default {
        components: {
            auth,
            headerTitle
        },
        computed: {
        },
        data(){
            return{
                title: '角色详情',
                companyList: [],
                organizationList: [],
                editForm: {
                    remarks: ''
                },
                form: {
                    status: true,
                    remarks: '',
                    roleName: '',
                    id: ''
                },
                accountRules: {},
                readonly: true, // 只读模式和编辑模式
                tableData: [],
                roleRules: {
                    roleName: [{required: true, message: '请输入角色名', trigger: 'change'},],
                    status: [{required: true, message: '请选择是否有效', trigger: 'change'}]
                },
                rolesList: [], // 角色列表
                roleShow: false, // 编辑角色flag
                roleId: '',
                userNum: 0, // 拥有该角色的人员数
                authTab: 'shujuquanxian',
            }
        },
        
        methods:{
            // 获取详情
            handleDetail() {
                let params = {
                    operationId: this.roleId,
                    applicationId: 1,
                    operationType: 1
                }
                Http.findRoleDetail(params).then(res=>{
                    this.editForm = res.data.data
                })
            },
            // 查看该角色拥有的用户列表
            handleQueryAuthUsers() {
                Http.queryUsersByRole({id: this.roleId}).then(res=>{
                    this.tableData = res.data.data
                    this.userNum = this.tableData.length
                }).catch(err=>{

                })
            },
            // dialog事件
            editRole() {
                let url = `/roleManageDetail/edit?id=${this.roleId}`
                this.$router.push(url)
                // this.$openTabSwitch('角色详情',url)
            }
        },
        created(){
            this.roleId = this.$route.query.id
            this.handleDetail()
            this.handleQueryAuthUsers()
        },
        filters:{
            sex(item) {
                return item == 1?'男':'女'
            },
            status(val) {
                return val == 1?'启用':'冻结'
            }
        },
    }
</script>

<style scoped>
.height100 {
    height: 100%;
}
.box {
    /* padding: 10px 20px; */
    background: #ffffff;
    height: calc(100% - 19px);
    margin: 4px 0 15px 0;
}
.box-l {
    border: 1px solid rgba(0,0,0,0.1);
    margin-left: 1.2%;
    overflow: auto;
}
.box-r {
    border: 1px solid rgba(0,0,0,0.1);
    margin-left: 1.2%;
}
.add-role-button {
    border-bottom: 1px solid #EDEDED;
    padding: 10px 13px 9px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.edit-button {
    display: flex;
    justify-content: flex-end;
    border-bottom: 1px solid #EDEDED;
    padding: 3px 9px;
}
.add_auth_button {
    float: right;
    margin-right: 9px;
}
.table-box {
    padding: 0 9px 14px 0;
}
.rs-form-item{
    margin-bottom:0;
}
/deep/.rs-form-item__label {
    color: #666666;
}
/deep/.rs-tabs__content {
    overflow: visible;
}
/deep/ .header{
    margin-bottom: 14px;
}
/deep/ .rs-table__row:hover{
    cursor: pointer;
}
</style>
